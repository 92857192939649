<!-- <div class="form-group d-flex flex-column">
  <label *ngIf="label?.length > 0" class="label"> {{label}}</label>

  <span class="input-group-text border-0 pl-0 d-flex flex-column" id="basic-addon1">
    <div>
      <img *ngIf="src != '' && src != null" class="img-fluid" style="overflow: hidden !important"
        (click)="fileInput.click()" [src]="src" [width]="width" [height]="height" />
    </div>
    <div style="margin-left: 10px" class="d-flex mt-3">

      <button class="btn btn-success btn-sm mr-2" title="Salvar Alteração" [hidden]="selectedFile == null" type="button"
        (click)="onUpload()">
        <i class="fa fa-check " aria-hidden="true"></i>
      </button>
      <button class="btn btn-danger btn-sm" title="Descartar Alteração" [hidden]="selectedFile == null" type="button"
        (click)="onCancelled()">
        <i class="fa fa-undo" aria-hidden="true"></i>
      </button>

    </div>
  </span>
  <div>
    <span class="help-block-error" *ngIf="errorMessage != null">{{errorMessage | translate}}</span>
  </div>
  <input style="display: none" #fileInput type="file" (change)="onFileSelected($event)" />
  <button class="btn btn-sm btn-cancelar mt-2" title="Alterar Imagem" [hidden]="selectedFile != null" type="button"
    (click)="fileInput.click()">
    <i class="fa fa-search" aria-hidden="true"></i>{{'Buscar' | translate}}
  </button>
</div> -->

<div class="balaoArquivo text-center d-flex mt-md-4 mt-2 p-2 p-md-2 justify-content-md-center"
  style="background-color: rgb(242, 242, 242); border-radius: 15px;">
  <div class="p-md-2" style="border: 1px dashed #A8A8A8; border-radius: 15px;">
    <img *ngIf="src == '' || src == null" src="assets/icons/arquivo.png" class="arquivo mt-md-3 mt-3 mb-2" alt="Arquivo">
    <img *ngIf="src != '' && src != null" class="img-fluid" style="overflow: hidden !important"
      (click)="fileInput.click()" src="assets/icons/ok.png" width="50" height="50" />

    <button class="btn btn-success btn-sm mr-2" title="Salvar Alteração" [hidden]="selectedFile == null" type="button"
      (click)="onUpload()">
      <i class="fa fa-check " aria-hidden="true"></i>
    </button>
    <button class="btn btn-danger btn-sm" title="Descartar Alteração" [hidden]="selectedFile == null" type="button"
      (click)="onCancelled()">
      <i class="fa fa-undo" aria-hidden="true"></i>
    </button>
    <div>
    <span class="help-block-error" *ngIf="errorMessage != null">{{errorMessage | translate}}</span>
  </div>
  <input style="display: none" #fileInput type="file" (change)="onFileSelected($event)" />
    <button [hidden]="selectedFile != null" (click)="fileInput.click()"
      class="botao ml-md-1 mr-md-1 mb-md-2 mb-3 pt-md-2 pt-2 pb-md-2 pb-2 pl-md-4 pl-4 pr-md-4 pr-4 rounded-pill"
      style="border-color: rgb(12, 159, 237); border: solid 2px;"><strong>Escolha
        o arquivo</strong>
    </button>
  </div>
</div>
